import request from '@/utils/http'

/**
 * 登录
 * @param payload
 */
export function auth_login(payload) {
    return request({ url: '/system/Passport/login', method: 'post', data: payload })
}

/**
 * 修改密码
 * @param payload
 */
export function update_password(payload) {
    return request({ url: '/system/AdminUser/updatePassWord', method: 'post', data: payload })
}

/**
 * 验证码
 * @param payload
 */
export function captcha(payload) {
    return request({ url: '/system/Passport/captcha', method: 'post', data: payload })
}