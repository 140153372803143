export default [
    {
        relyon: '',
        title: '订单',
        path: '/order',
        name: 'order',
        hidden: false,
        sort: 7,
        component: '',
        redirect: '/order/manage',
        children: [
            {
                title: '订单管理',
                path: '/order/manage',
                name: 'order_manage',
                hidden: false,
                component: '',
                redirect: '/order/manage/list',
                children: [
                    {
                        title: '订单列表',
                        path: '/order/manage/list',
                        name: 'order_manage_list',
                        hidden: false,
                        component: () => import('@/addons/order/manage/List.vue'),
                        redirect: '',
                    },
                    {
                        title: '订单详情',
                        path: '/order/manage/detail',
                        name: 'order_manage_detail',
                        hidden: true,
                        component: () => import('@/addons/order/manage/Detail.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];