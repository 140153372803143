export default [
    {
        relyon: 'setting',
        title: '存储设置',
        path: '/storage/setting',
        name: 'storage_setting',
        hidden: false,
        component: '',
        redirect: '/storage/setting/upload',
        children: [
            {
                title: '上传设置',
                path: '/storage/setting/upload',
                name: 'storage_setting_upload',
                hidden: false,
                component: () => import('@/addons/storage/setting/Upload.vue'),
                redirect: '',
                operate: [
                    {
                        title: '配置',
                        name: 'storage_setting_set',
                    },
                ],
            },
        ]
    },
    {
        relyon: 'manage',
        title: '文件管理',
        path: '/storage/files',
        name: 'storage_files',
        hidden: false,
        component: '',
        redirect: '/storage/files/file',
        children: [
            {
                title: '文件列表',
                path: '/storage/files/file',
                name: 'storage_files_file',
                hidden: false,
                component: () => import('@/addons/storage/files/File.vue'),
                redirect: '',
                operate: [
                    {
                        title: '编辑',
                        name: 'storage_files_edit',
                    },
                    {
                        title: '删除',
                        name: 'storage_files_delete',
                    },
                ],
            },
            {
                title: '文件分组',
                path: '/storage/files/group',
                name: 'storage_files_group',
                hidden: false,
                component: () => import('@/addons/storage/files/Group.vue'),
                redirect: '',
                operate: [
                    {
                        title: '添加',
                        name: 'storage_group_create',
                    },
                    {
                        title: '编辑',
                        name: 'storage_group_edit',
                    },
                    {
                        title: '删除',
                        name: 'storage_group_delete',
                    },
                ],
            },
            {
                title: '测试demo',
                path: '/storage/files/test',
                name: 'storage_files_test',
                hidden: false,
                component: () => import('@/addons/storage/Test.vue'),
                redirect: '',
            },
        ]
    }
];