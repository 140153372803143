import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import { authController } from "@/router/auth";
import { createRouteByList } from "@/router/addon";

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

//动态添加路由
const routes_remote = createRouteByList();
routes_remote.forEach(item => {
    router.addRoute('root', item);
});

router.beforeEach(authController);

export default router;