import { h, resolveComponent } from "vue";

// 直接嵌套页面，避免重复嵌套
const RouteView = {
    name: 'RouteView',
    render: () => h(resolveComponent("router-view"))
}
let number = 1;

// 遍历路由
export const createRouteMenu = (menuList) => {
    const userRoutes = menuList.map(menu => {
        const { title, sort, name, path, redirect, hidden = false, component, children = [], operate } = menu;
        number = number + 1;
        const currentMenu = {
            id: number,
            name, path, redirect, hidden, sort,
            component: component == '' ? RouteView : component,
            meta: { title },
            children: children.length === 0 ? [] : createRouteMenu(children),
            operate: operate ? operate : [],
        }
        if (children.length <= 0) {
            delete currentMenu.children
        }
        return currentMenu
    })
    return userRoutes
}

// 获取所有插件路由信息
export const createRouteByList = (is_root = false) => {
    const files = require.context('@/addons', true, /routes.js$/);

    let top_level = [];
    let child_level = [];

    files.keys().forEach(key => {
        if (files(key).default) {
            files(key).default.forEach(item => {
                if (item.relyon == "") {
                    top_level.push(item);
                } else {
                    child_level.push(item);
                }
            });
        }
    })

    let modules = top_level;

    top_level.find((item, index) => {
        child_level.forEach(itemc => {
            if (item.name == itemc.relyon && modules[index].children.indexOf(itemc) == -1) {
                modules[index].children.push(itemc);
            }
        });
    });

    let auth_routes = createRouteMenu(modules);
    auth_routes.sort(function (x, y) {
        return x['sort'] - y['sort'];
    });

    if (is_root == true) {
        return auth_routes;
    }

    // 获取登录授权得到的权限列表
    let auth_list_str = localStorage.getItem('USER_AUTH');
    if (!auth_list_str) return [];

    let auth_array = auth_list_str.split(",");
    return filterRoutes(auth_routes, auth_array);
}

// 去掉没有权限的栏目
export const filterRoutes = (route_all, auth_array) => {
    const user_auth = route_all.filter(item => {
        if (auth_array.indexOf(item.name) > -1) {
            if (item.children && item.children.length > 0) {
                item.children = filterRoutes(item.children, auth_array);
            }
            return item;
        } else {
            return false;
        }
    })
    return user_auth;
}