<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider
      v-model:collapsed="collapsed"
      collapsible
      class="console-left"
      :width="150"
    >
      <div class="console-logo">
        <img class="logo" :src="require('@/assets/img/ar-logo.png')" />
        运维平台
      </div>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item key="100">
          <bank-outlined />
          <span>概览</span>
        </a-menu-item>
        <a-menu-item key="101">
          <hdd-outlined />
          <span>主机管理</span>
        </a-menu-item>
        <a-menu-item key="102">
          <mac-command-outlined />
          <span>服务管理</span>
        </a-menu-item>
        <a-menu-item key="103">
          <group-outlined />
          <span>机房管理</span>
        </a-menu-item>
        <a-menu-item key="103">
          <ungroup-outlined />
          <span>异常管理</span>
        </a-menu-item>
        <a-menu-item key="104">
          <notification-outlined />
          <span>消息通知</span>
        </a-menu-item>
        <a-menu-item key="105">
          <verified-outlined />
          <span>测试工具</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout class="content-body">
      <a-layout-header class="header" />
      <a-layout-content class="body-box">
        <a-breadcrumb>
          <a-breadcrumb-item>User</a-breadcrumb-item>
          <a-breadcrumb-item>Bill</a-breadcrumb-item>
        </a-breadcrumb>
        <div>
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import {
  BankOutlined,
  HddOutlined,
  MacCommandOutlined,
  GroupOutlined,
  UngroupOutlined,
  NotificationOutlined,
  VerifiedOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import "./global.less";
export default defineComponent({
  components: {
    BankOutlined,
    HddOutlined,
    MacCommandOutlined,
    GroupOutlined,
    UngroupOutlined,
    NotificationOutlined,
    VerifiedOutlined,
  },

  data() {
    return {
      collapsed: ref(false),
      selectedKeys: ref(["100"]),
    };
  },
});
</script>