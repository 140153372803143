import axios from 'axios'
import { notification } from 'ant-design-vue';
import store from '@/store'
import storage from 'store'
import { message } from "ant-design-vue";

// console.log(process.env);
// 创建一个axios实例
const service = axios.create({
    // api的base_url
    baseURL: process.env.VUE_APP_API_BASE_URL,

    // request timeout
    timeout: 30000,
    // 使用简单请求,复杂请求(多一次OPTIONS请求)可用 application/json
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    }
})

// 请求拦截器
service.interceptors.request.use(config => {
    // 在发送请求之前做某事，比如说 设置token

    // 更新加载状态
    store.dispatch('loading/setloading', true);

    const token = storage.get('ACCESS_TOKEN')
    // console.log(token);
    if (token) {
        config.headers['Access-Token'] = token
    }
    return config
}, error => {
    // 请求错误时做些事
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
    response => {
        store.dispatch('loading/setloading', false);

        const result = response.data
        // result.status [ 200正常 500有错误 401未登录 403没有权限访问 ]
        if (result.status === 500) {
            // return Promise.reject(result.message ? result.message : '网络错误');
        }
        if (result.status === 400) {
            message.error(result.message ? result.message : '请求错误');
        }
        if (result.status === 403) {
            return Promise.reject(result.message ? result.message : '没有访问权限');
        }
        if (result.status === 401) {
            store.dispatch('Logout').then(() => {
                notification.error({
                    message: '错误',
                    description: result.message,
                    duration: 3
                })
                setTimeout(() => {
                    window.location.reload();
                }, 1200)
            })
            return Promise.reject(result);
        }
        return result;
    }, error => {
        store.dispatch('loading/setloading', false);
        if(error.response.status==401){

            localStorage.removeItem('ACCESS_TOKEN')
        }
        // 网络请求出错
        const errMsg = ((error.response || {}).data || {}).message || '请求出现错误，请稍后再试'
        notification.error({
            message: '网络请求出错',
            description: errMsg,
            duration: 3
        })
        return Promise.reject(error)
    }
);

export default service
