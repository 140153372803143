export default [
    {
        relyon: '',
        title: '应用',
        path: '/client',
        name: 'client',
        hidden: false,
        sort: 4,
        component: '',
        redirect: '/client/app',
        children: [
            {
                title: '应用列表',
                path: '/client/app',
                name: 'client_app',
                hidden: false,
                component: '',
                redirect: '/client/app/list',
                children: [
                    {
                        title: '所有应用',
                        path: '/client/app/list',
                        name: 'client_app_list',
                        hidden: false,
                        component: () => import('@/addons/client/app/List.vue'),
                        redirect: '',
                        operate: [
                            {
                                title: '查看',
                                name: 'client_app_scan',
                            },
                        ],
                    },
                    {
                        title: '创建应用',
                        path: '/client/app/create',
                        name: 'client_app_create',
                        hidden: false,
                        component: () => import('@/addons/client/app/Create.vue'),
                        redirect: '',
                    },
                    {
                        title: '微信公众号详情',
                        path: '/client/app/wechat',
                        name: 'client_app_wechat',
                        hidden: true,
                        component: () => import('@/addons/client/app/WeChat.vue'),
                        redirect: '',
                    },
                    {
                        title: '微信小程序详情',
                        path: '/client/app/wxapp',
                        name: 'client_app_wxapp',
                        hidden: true,
                        component: () => import('@/addons/client/app/Wxapp.vue'),
                        redirect: '',
                    },
                    {
                        title: '安卓APP详情',
                        path: '/client/app/android',
                        name: 'client_app_android',
                        hidden: true,
                        component: () => import('@/addons/client/app/Android.vue'),
                        redirect: '',
                    },
                    {
                        title: '苹果APP详情',
                        path: '/client/app/ios',
                        name: 'client_app_ios',
                        hidden: true,
                        component: () => import('@/addons/client/app/IOS.vue'),
                        redirect: '',
                    },
                    {
                        title: 'H5详情',
                        path: '/client/app/h5',
                        name: 'client_app_h5',
                        hidden: true,
                        component: () => import('@/addons/client/app/H5.vue'),
                        redirect: '',
                    },
                    {
                        title: 'PC网站详情',
                        path: '/client/app/native',
                        name: 'client_app_native',
                        hidden: true,
                        component: () => import('@/addons/client/app/Native.vue'),
                        redirect: '',
                    },
                    //微信公众号相关相关
                    {
                        title: '微信公众号设置',
                        path: '/client/wechat/config',
                        name: 'client_wechat_config',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/Config.vue'),
                        redirect: '',
                    },
                    {
                        title: '自定义菜单',
                        path: '/client/wechat/menu',
                        name: 'client_wechat_menu',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/Menu.vue'),
                        redirect: '',
                    },
                    {
                        title: '图文列表',
                        path: '/client/wechat/material',
                        name: 'client_wechat_material',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/Material.vue'),
                        redirect: '',
                    },
                    {
                        title: '粉丝列表',
                        path: '/client/wechat/fans',
                        name: 'client_wechat_fans',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/Fans.vue'),
                        redirect: '',
                    },
                    {
                        title: '回复设置',
                        path: '/client/wechat/replay',
                        name: 'client_wechat_replay',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/Replay.vue'),
                        redirect: '',
                    },
                    {
                        title: '模板消息',
                        path: '/client/wechat/message',
                        name: 'client_wechat_message',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/Message.vue'),
                        redirect: '',
                    },
                    {
                        title: '发布图文',
                        path: '/client/wechat/material_publish',
                        name: 'client_wechat_material_publish',
                        hidden: true,
                        component: () => import('@/addons/client/wechat/MaterialPublish.vue'),
                        redirect: '',
                    },
                    //微信小程序相关
                    {
                        title: '微信小程序设置',
                        path: '/client/wxapp/config',
                        name: 'client_wxapp_config',
                        hidden: true,
                        component: () => import('@/addons/client/wxapp/Config.vue'),
                        redirect: '',
                    },
                    //PC网站相关
                    {
                        title: '网站设置',
                        path: '/client/native/config',
                        name: 'client_native_config',
                        hidden: true,
                        component: () => import('@/addons/client/native/Config.vue'),
                        redirect: '',
                    },
                    {
                        title: '网站SEO设置',
                        path: '/client/native/seo',
                        name: 'client_native_seo',
                        hidden: true,
                        component: () => import('@/addons/client/native/Seo.vue'),
                        redirect: '',
                    },
                    {
                        title: '友情链接设置',
                        path: '/client/native/links',
                        name: 'client_native_links',
                        hidden: true,
                        component: () => import('@/addons/client/native/Links.vue'),
                        redirect: '',
                    },
                    {
                        title: '版权及ICP备案',
                        path: '/client/native/copyright',
                        name: 'client_native_copyright',
                        hidden: true,
                        component: () => import('@/addons/client/native/Copyright.vue'),
                        redirect: '',
                    },
                    {
                        title: '站点装修',
                        path: '/client/native/web',
                        name: 'client_native_web',
                        hidden: true,
                        component: () => import('@/addons/client/native/Web.vue'),
                        redirect: '',
                    },
                    {
                        title: '开发者文档',
                        path: '/client/native/developer',
                        name: 'client_native_developer',
                        hidden: true,
                        component: () => import('@/addons/client/native/Developer.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];