export default [
    {
        relyon: 'setting',
        title: '支付设置',
        path: '/payment/setting',
        name: 'payment_setting',
        hidden: false,
        component: '',
        redirect: '/payment/setting/list',
        children: [
            {
                title: '支付方式',
                path: '/payment/setting/list',
                name: 'payment_setting_list',
                hidden: false,
                component: () => import('@/addons/payment/setting/List.vue'),
                redirect: '',
                operate: [
                    {
                        title: '配置',
                        name: 'payment_setting_set',
                    },
                ],
            },
            {
                title: '微信支付',
                path: '/payment/setting/wechat',
                name: 'payment_setting_wechat',
                hidden: true,
                component: () => import('@/addons/payment/setting/Wechat.vue'),
                redirect: '',
            },
            {
                title: '支付宝支付',
                path: '/payment/setting/alipay',
                name: 'payment_setting_alipay',
                hidden: true,
                component: () => import('@/addons/payment/setting/Alipay.vue'),
                redirect: '',
            },
        ]
    },
];