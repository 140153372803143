export default [
    {
        relyon: 'setting',
        title: '短信设置',
        path: '/sms/setting',
        name: 'sms_setting',
        hidden: false,
        component: '',
        redirect: '/sms/setting/list',
        children: [
            {
                title: '配置信息',
                path: '/sms/setting/list',
                name: 'sms_setting_list',
                hidden: false,
                component: () => import('@/addons/sms/setting/List.vue'),
                redirect: '',
                operate: [
                    {
                        title: '配置',
                        name: 'sms_setting_set',
                    },
                    {
                        title: '测试',
                        name: 'sms_setting_test',
                    },
                ],
            },
            {
                title: '模板管理',
                path: '/sms/setting/template',
                name: 'sms_setting_template',
                hidden: false,
                component: () => import('@/addons/sms/setting/Template.vue'),
                redirect: '',
            },
        ]
    },
];