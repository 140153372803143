export default [
    {
        relyon: '',
        title: '内容',
        path: '/cms',
        name: 'cms',
        hidden: false,
        sort: 6,
        component: '',
        redirect: '/cms/content',
        children: [
            {
                title: '内容管理',
                path: '/cms/content',
                name: 'cms_content',
                hidden: false,
                component: '',
                redirect: '/cms/content/list',
                children: [
                    {
                        title: '文档列表',
                        path: '/cms/content/list',
                        name: 'cms_content_list',
                        hidden: false,
                        component: () => import('@/addons/cms/content/List.vue'),
                        redirect: '',
                    },
                    {
                        title: '发布文档',
                        path: '/cms/content/publish',
                        name: 'cms_content_publish',
                        hidden: true,
                        component: () => import('@/addons/cms/content/modules/CreateArticle.vue'),
                        redirect: '',
                    },
                    {
                        title: '编辑文档',
                        path: '/cms/content/update',
                        name: 'cms_content_update',
                        hidden: true,
                        component: () => import('@/addons/cms/content/modules/UpdateArticle.vue'),
                        redirect: '',
                    },
                    {
                        title: '栏目管理',
                        path: '/cms/content/category',
                        name: 'cms_content_category',
                        hidden: false,
                        component: () => import('@/addons/cms/content/Category.vue'),
                        redirect: '',
                    },
                    {
                        title: '草稿箱',
                        path: '/cms/content/draft',
                        name: 'cms_content_draft',
                        hidden: false,
                        component: () => import('@/addons/cms/content/Draft.vue'),
                        redirect: '',
                    },
                    {
                        title: '回收站',
                        path: '/cms/content/recycle',
                        name: 'cms_content_recycle',
                        hidden: false,
                        component: () => import('@/addons/cms/content/Recycle.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];