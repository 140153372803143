export default [
    {
        relyon: '',
        title: '会员',
        path: '/user',
        name: 'user',
        hidden: false,
        sort: 7,
        component: '',
        redirect: '/user/manage',
        children: [
            {
                title: '会员管理',
                path: '/user/manage',
                name: 'user_manage',
                hidden: false,
                component: '',
                redirect: '/user/manage/list',
                children: [
                    {
                        title: '会员列表',
                        path: '/user/manage/list',
                        name: 'user_manage_list',
                        hidden: false,
                        component: () => import('@/addons/user/manage/List.vue'),
                        redirect: '',
                    },
                    {
                        title: '会员留言',
                        path: '/user/manage/usermessage',
                        name: 'user_message_list',
                        hidden: false,
                        component: () => import('@/addons/user/manage/usermessage.vue'),
                        redirect: '',
                    },
                    {
                        title: '会员等级',
                        path: '/user/manage/level',
                        name: 'user_manage_level',
                        hidden: false,
                        component: () => import('@/addons/user/manage/Level.vue'),
                        redirect: '',
                    },
                    {
                        title: '会员标签',
                        path: '/user/manage/label',
                        name: 'user_manage_label',
                        hidden: false,
                        component: () => import('@/addons/user/manage/Label.vue'),
                        redirect: '',
                    },
                    {
                        title: '会员导入',
                        path: '/user/manage/import',
                        name: 'user_manage_import',
                        hidden: false,
                        component: () => import('@/addons/user/manage/Import.vue'),
                        redirect: '',
                    },
                    {
                        title: '黑名单',
                        path: '/user/manage/black',
                        name: 'user_manage_black',
                        hidden: false,
                        component: () => import('@/addons/user/manage/Black.vue'),
                        redirect: '',
                    },
                ]
            },
            {
                title: '会员运营',
                path: '/user/promotion',
                name: 'user_promotion',
                hidden: false,
                component: '',
                redirect: '/user/promotion/cluster',
                children: [
                    {
                        title: '会员群体',
                        path: '/user/promotion/cluster',
                        name: 'user_promotion_cluster',
                        hidden: false,
                        component: () => import('@/addons/user/promotion/Cluster.vue'),
                        redirect: '',
                    },
                    {
                        title: '会员分析',
                        path: '/user/promotion/analyse',
                        name: 'user_promotion_analyse',
                        hidden: false,
                        component: () => import('@/addons/user/promotion/Analyse.vue'),
                        redirect: '',
                    },
                ]
            },
            {
                title: '会员设置',
                path: '/user/setting',
                name: 'user_psetting',
                hidden: false,
                component: '',
                redirect: '/user/setting/login',
                children: [
                    {
                        title: '登录设置',
                        path: '/user/setting/login',
                        name: 'user_setting_login',
                        hidden: false,
                        component: () => import('@/addons/user/setting/Login.vue'),
                        redirect: '',
                    },
                    {
                        title: '注册设置',
                        path: '/user/setting/register',
                        name: 'user_setting_register',
                        hidden: false,
                        component: () => import('@/addons/user/setting/Register.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];