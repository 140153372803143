export default [
    {
        relyon: '',
        title: '首页',
        path: '/index',
        name: 'index',
        hidden: false,
        sort: 1,
        component: () => import('@/addons/index/Index.vue'),
        redirect: '',
    }
];