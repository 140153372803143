export default [
    {
        relyon: 'cms',
        title: '广告管理',
        path: '/advs/manage',
        name: 'advs_manage',
        hidden: false,
        component: '',
        redirect: '/advs/manage/list',
        children: [
            {
                title: '广告列表',
                path: '/advs/manage/list',
                name: 'advs_manage_list',
                hidden: false,
                component: () => import('@/addons/advs/manage/List.vue'),
                redirect: '',
                operate: [
                    {
                        title: '统计',
                        name: 'advs_manage_stat',
                    },
                    {
                        title: '编辑',
                        name: 'advs_manage_edit',
                    },
                    {
                        title: '删除',
                        name: 'advs_manage_delete',
                    },
                ],
            },
            {
                title: '发布广告',
                path: '/advs/manage/create',
                name: 'advs_manage_create',
                hidden: false,
                component: () => import('@/addons/advs/manage/Create.vue'),
                redirect: '',
            },
            {
                title: '编辑广告',
                path: '/advs/manage/edit',
                name: 'advs_manage_edit',
                hidden: true,
                component: () => import('@/addons/advs/manage/Edit.vue'),
                redirect: '',
            },
            {
                title: '广告分析',
                path: '/advs/manage/analyse',
                name: 'advs_manage_analyse',
                hidden: true,
                component: () => import('@/addons/advs/manage/Analyse.vue'),
                redirect: '',
            },
        ]
    }
];