import { h, resolveComponent } from "vue";
import { Layout } from '@/layout'
import { ConsoleLayout } from '@/addons/console/layout'


// 直接嵌套页面，避免重复嵌套
const RouteView = {
    name: 'RouteView',
    render: () => h(resolveComponent("router-view"))
}

/// 基础路由
export default [
    {
        path: '/',
        name: 'root',
        meta: { title: '首页' },
        hidden: false,
        component: Layout,
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'index',
                meta: { title: '首页' },
                component: () => import('@/addons/index/Index.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('@/views/passport/login.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: RouteView,
        redirect: '/404',
        children: [
            {
                path: '/403',
                name: '403',
                meta: { title: '404 page' },
                component: () => import('@/views/error/403.vue'),
            },
            {
                path: '/404',
                name: '404',
                meta: { title: '404 page' },
                component: () => import('@/views/error/404.vue'),
            },
            {
                path: '/500',
                name: '500',
                meta: { title: '404 page' },
                component: () => import('@/views/error/500.vue'),
            },
            {
                path: '/blank',
                name: 'blank',
                meta: { title: '空白页' },
                component: () => import('@/views/error/blank.vue'),
            },
        ]
    },
    {
        path: '/install',
        name: 'install',
        meta: { title: '安装程序' },
        component: () => import('@/addons/install/Index.vue'),
    },
    {
        path: '/console',
        name: 'console',
        component: ConsoleLayout,
        redirect: '/console/index',
        children: [
            {
                path: '/console/index',
                name: 'console_index',
                meta: { title: '运维中心' },
                component: () => import('@/addons/console/index.vue'),
            },
        ]
    },
]
