export default [
    {
        relyon: '',
        title: '设置',
        path: '/setting',
        name: 'setting',
        hidden: false,
        sort: 3,
        component: '',
        redirect: '/setting/system',
        children: [
            {
                title: '系统设置',
                path: '/setting/system',
                name: 'setting_system',
                hidden: false,
                component: '',
                redirect: '/setting/system/site',
                children: [
                    {
                        title: '站点设置',
                        path: '/setting/system/site',
                        name: 'setting_system_site',
                        hidden: false,
                        component: () => import('@/addons/setting/system/Site.vue'),
                        redirect: '',
                    },
                    {
                        title: '版权信息',
                        path: '/setting/system/copyright',
                        name: 'setting_system_copyright',
                        hidden: false,
                        component: () => import('@/addons/setting/system/Copyright.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];