export default [
    {
        relyon: '',
        title: '管理',
        path: '/manage',
        name: 'manage',
        hidden: false,
        sort: 2,
        component: '',
        redirect: '/manage/system',
        children: [
            {
                title: '系统管理',
                path: '/manage/system',
                name: 'manage_system',
                hidden: false,
                component: '',
                redirect: '/manage/system/user',
                children: [
                    {
                        title: '用户管理',
                        path: '/manage/system/user',
                        name: 'manage_system_user',
                        hidden: false,
                        component: () => import('@/addons/manage/system/User.vue'),
                        redirect: '',
                        operate: [
                            {
                                title: '添加',
                                name: 'manage_system_user_create',
                            },
                            {
                                title: '编辑',
                                name: 'manage_system_user_edit',
                            },
                            {
                                title: '删除',
                                name: 'manage_system_user_delete',
                            },
                        ],
                    },
                    {
                        title: '角色管理',
                        path: '/manage/system/role',
                        name: 'manage_system_role',
                        hidden: false,
                        component: () => import('@/addons/manage/system/Role.vue'),
                        redirect: '',
                        operate: [
                            {
                                title: '添加',
                                name: 'manage_system_role_create',
                            },
                            {
                                title: '编辑',
                                name: 'manage_system_role_edit',
                            },
                            {
                                title: '删除',
                                name: 'manage_system_role_delete',
                            },
                        ],
                    },
                    {
                        title: '部门管理',
                        path: '/manage/system/dept',
                        name: 'manage_system_dept',
                        hidden: false,
                        component: () => import('@/addons/manage/system/Dept.vue'),
                        redirect: '',
                        operate: [
                            {
                                title: '添加',
                                name: 'manage_system_dept_create',
                            },
                            {
                                title: '编辑',
                                name: 'manage_system_dept_edit',
                            },
                            {
                                title: '删除',
                                name: 'manage_system_dept_delete',
                            },
                        ],
                    },
                ]
            },
            {
                title: '菜单管理',
                path: '/manage/menu',
                name: 'manage_menu',
                hidden: false,
                component: '',
                redirect: '/manage/menu/list',
                children: [
                    {
                        title: '菜单列表',
                        path: '/manage/menu/list',
                        name: 'manage_menu_list',
                        hidden: false,
                        component: () => import('@/addons/manage/menu/List.vue'),
                        redirect: '',
                    },
                    {
                        title: 'API权限',
                        path: '/manage/menu/api',
                        name: 'manage_menu_api',
                        hidden: false,
                        component: () => import('@/addons/manage/menu/Api.vue'),
                        redirect: '',
                    },
                ]
            },
            {
                title: '数据管理',
                path: '/manage/data',
                name: 'manage_data',
                hidden: false,
                component: '',
                redirect: '/manage/data/list',
                children: [
                    {
                        title: '菜单列表',
                        path: '/manage/data/backup',
                        name: 'manage_data_backup',
                        hidden: false,
                        component: () => import('@/addons/manage/data/Backup.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];