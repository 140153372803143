export default [
    {
        relyon: '',
        title: '插件',
        path: '/addon',
        name: 'addon',
        hidden: false,
        sort: 100,
        component: '',
        redirect: '/addon/plugin',
        children: [
            {
                title: '插件管理',
                path: '/addon/plugin',
                name: 'addon_plugin',
                hidden: false,
                component: '',
                redirect: '/addon/plugin/manage',
                children: [
                    {
                        title: '插件列表',
                        path: '/addon/plugin/manage',
                        name: 'addon_plugin_manage',
                        hidden: false,
                        component: () => import('@/addons/addon/plugin/Manage.vue'),
                        redirect: '',
                        operate: [
                            {
                                title: '安装',
                                name: 'addon_plugin_install',
                            },
                            {
                                title: '卸载',
                                name: 'addon_plugin_unstall',
                            },
                            {
                                title: '详情',
                                name: 'addon_plugin_info',
                            },
                        ],
                    },
                    {
                        title: '插件市场',
                        path: '/addon/plugin/market',
                        name: 'addon_plugin_market',
                        hidden: false,
                        component: () => import('@/addons/addon/plugin/Market.vue'),
                        redirect: '',
                    },
                    {
                        title: '创建插件',
                        path: '/addon/plugin/publish',
                        name: 'addon_plugin_publish',
                        hidden: false,
                        component: () => import('@/addons/addon/plugin/Publish.vue'),
                        redirect: '',
                    },
                ]
            },
        ]
    }
];