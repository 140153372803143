export default [
    {
        relyon: 'cms',
        title: '移动页面',
        path: '/h5web/page',
        name: 'h5web_page',
        hidden: false,
        component: '',
        redirect: '/h5web/page/design',
        children: [
            {
                title: '页面设计',
                path: '/h5web/page/index',
                name: 'h5web_page_index',
                hidden: false,
                component: () => import('@/addons/h5web/page/Index.vue'),
                redirect: '',
            },
            {
                title: '设计页面',
                path: '/h5web/page/design',
                name: 'h5web_page_design',
                hidden: true,
                component: () => import('@/addons/h5web/page/Design.vue'),
                redirect: '',
            },
        ]
    }
];