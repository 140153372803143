<template>
  <a-spin :spinning="spinning.loading" :delay="300">
    <a-layout class="site-page">
      <a-layout-header class="site-header">
        <div class="site-logo">
<!--          <img alt="site logo" src="@/assets/logo.png" />-->
          四川省樂至县中医医院
        </div>
        <a-menu theme="light" mode="horizontal" v-model:selectedKeys="menuStatusParam.sub_selected_key">
          <a-menu-item v-for="item in menuStatusParam.sub_menus" :key="item.id">
            <router-link :to="item.path">{{ item.meta.title }}</router-link>
          </a-menu-item>
        </a-menu>
        <div class="site-setting">
          <a-dropdown class="setting-item notice-item" placement="bottomLeft">
            <BellOutlined />
          </a-dropdown>
          <a-dropdown class="setting-item avatar-item" placement="bottomLeft">
            <a-button type="text">{{ admin_user }}
              <DownOutlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" @click="updatePassWord">修改密码</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click.stop="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content class="site-body">
        <a-layout class="site-body-layout">
          <a-layout-sider width="200" class="site-body-left" v-if="menuStatusParam.second_menus.length > 0">
            <a-menu v-model:selectedKeys="menuStatusParam.selected_key" :open-keys="menuStatusParam.open_keys" mode="inline">
              <template v-for="item in menuStatusParam.second_menus">
                <a-sub-menu v-if="item.children && !item.hidden" :title="item.meta.title" :key="item.id">
                  <template v-for="child in item.children">
                    <a-sub-menu :title="child.meta.title" v-if="child.children && !child.hidden" :key="child.id">
                      <a-menu-item v-for="grandson in child.children" :key="grandson.id">
                        <router-link v-if="!grandson.hidden" :to="grandson.path">{{ grandson.meta.title }}</router-link>
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item v-else-if="!child.hidden" :key="child.id">
                      <router-link :to="child.path">{{
                      child.meta.title
                    }}</router-link>
                    </a-menu-item>
                  </template>
                </a-sub-menu>
                <template v-else-if="!item.hidden">
                  <a-menu-item :key="item.id">
                    <router-link :to="item.path">{{
                    item.meta.title
                  }}</router-link>
                  </a-menu-item>
                </template>
              </template>
            </a-menu>
          </a-layout-sider>
          <a-layout-content class="site-body-content" v-if="menuStatusParam.second_menus.length > 0">
            <a-breadcrumb class="site-breadcrumb">
              <a-breadcrumb-item v-for="(item,index) in localNav" :key="index">{{item.meta.title}}</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="site-main">
              <router-view></router-view>
            </div>
          </a-layout-content>
          <a-layout-content class="site-body-content" v-else>
            <div class="site-index-main">
              <router-view></router-view>
            </div>
            <a-layout-footer style="text-align: center">
              Crate ©2022 Created by www.scwjnet.com
            </a-layout-footer>
          </a-layout-content>
        </a-layout>
      </a-layout-content>
    </a-layout>
    <a-modal v-model:visible="chat_visible" width="1200px" title="客服系统">
      <Chat msg="这里是客服工作台" />
    </a-modal>

    <a-modal v-model:visible="update_password_vidible" title="修改密码" :maskClosable="false" @ok="submitUpdatePassWord" :width="500">
      <a-form ref="formRef" v-bind="layout" :model="formState" @finish="submitUpdatePassWord">
        <a-form-item label="原密码" name="old_password" :rules="[{ required: true, message: '请输入原密码!' }]">
          <a-input v-model:value="formState.old_password" type="password" placeholder="请输入原密码" autocomplete="off" />
        </a-form-item>
        <a-form-item label="新密码" name="password" :rules="[{ required: true, message: '请输入新密码!' }]">
          <a-input v-model:value="formState.password" type="password" placeholder="请输入新密码" autocomplete="off" />
        </a-form-item>
        <a-form-item label="确认密码" name="check_password" :rules="[{ required: true, message: '请再次确认密码!' }]">
          <a-input v-model:value="formState.check_password" type="password" placeholder="请再次确认密码" autocomplete="off" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-spin>
</template>
<script>
import { defineComponent, computed, ref, reactive } from "vue";
import { BellOutlined, DownOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { update_password } from "@/store/api";
import { computedMenuStatus } from "@/utils/common";
import Chat from "@/components/Chat";
import { createRouteByList } from "@/router/addon";

export default defineComponent({
  name: "basic",
  components: {
    Chat,
    BellOutlined,
    DownOutlined,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const admin_user = ref(store.state.user.username);

    // 获取本地固定路由下所有路由信息
    const menus = createRouteByList();
    // console.log(menus)
    // 注销登录
    const logout = () => {
      store.dispatch("user/logout");
      router.push({ path: "/login" });
      localStorage.clear();
    };

    const localNav = computed(() => {
      return route.matched;
    });

    //动态获取菜单，一级选中的key，侧边选中的key，打开的key
    const menuStatusParam = computed(() => {
      return computedMenuStatus(menus, route.path);
    });

    const chat_visible = ref(false);

    const openKeFu = () => {
      chat_visible.value = true;
    };

    // 重置密码
    const formRef = ref();

    const formState = reactive({
      old_password: "",
      password: "",
      check_password: "",
    });

    const update_password_vidible = ref(false);

    const updatePassWord = () => {
      update_password_vidible.value = true;
    };

    const submitUpdatePassWord = () => {
      formRef.value
        .validate()
        .then(() => {
          update_password(formState).then((res) => {
            if (res.status === 200) {
              message.success(res.message);
            } else {
              message.error(res.message);
            }
            update_password_vidible.value = false;
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const spinning = ref(
      computed(() => {
        return store.state.loading;
      })
    );

    return {
      spinning,
      admin_user,
      localNav,
      logout,
      formRef,
      formState,
      update_password_vidible,
      updatePassWord,
      submitUpdatePassWord,
      menuStatusParam,
      openKeFu,
      chat_visible,
    };
  },
});
</script>
